var render = function render(){var _vm=this,_c=_vm._self._c;return _c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [(['role', 'staff', 'leader', 'director'].includes(_vm.role))?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('progress_management'),
        icon: 'fal fa-chart-pie',
        path: '/bao-cao-tien-do',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyKeToan())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('model.clients.payroll'),
        icon: 'fal fa-badge-dollar',
        path: '/quan-ly-ke-toan',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessKhachHang())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('customer_in_charge'),
        icon: 'fal fa-users',
        path: '/khach-hang',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessYeuCauHoTro())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('request_support'),
        icon: 'fal fa-square-question',
        path: '/yeu-cau-ho-tro',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canBilling())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('model.contracts.title'),
        icon: 'fal fa-books',
        path: '/contracts',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canBilling())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('debit_note'),
        icon: 'fal fa-books',
        path: '/debit-note',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessFAQ())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('question_library_QA'),
        icon: 'fal fa-books',
        path: '/FAQ',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessCongThucChung())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('formula_function'),
        icon: 'fal fa-flask',
        path: '/quan-ly-cong-thuc-chung',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessThuVienCauHoi())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('question_library'),
        icon: 'fal fa-books',
        path: '/thu-vien-cau-hoi',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessSalaryStatistics())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('statistics_accounts_expenses'),
        icon: 'fal fa-money-bill-1-wave',
        path: '/thong-ke-giai-doan-tinh-luong',
      }}}):_vm._e(),_vm._v(" "),_c('h6',{staticClass:"navbar-heading p-0 text-muted mt-md-4"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('management')))]),_vm._v(" "),_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"fal fa-ellipsis"}})],1)]),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('model.buttons.approved_request'),
        icon: 'fal fa-clipboard-check',
        path: '/yeu-cau-duyet',
        badge: _vm.assignedCount,
      }}}),_vm._v(" "),(_vm.roleManager.canAccessQuanLyKhachHangMoi())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('manage_new_customer'),
        icon: 'fal fa-user-check',
        path: '/quan-ly-khach-hang-moi',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyNhanVienIGLocal())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('model.employees.list'),
        icon: 'fal fa-users',
        path: '/nhan-vien',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyKhachHangMoi())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('model.clients.list'),
        icon: 'fal fa-users-medical',
        path: '/quan-ly-danh-sach-khach-hang',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessDebitRequest())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('expense_management'),
        icon: 'fal fa-money-check-dollar-pen',
        path: '/quan-ly-chi-ho',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessNgayNghiLe())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('holidays'),
        icon: 'fal fa-calendar-alt',
        path: '/ngay-nghi-le',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyTemplate())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('template'),
        icon: 'fal fa-file-pen',
        path: '/quan-ly-template',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyTemplate())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('manage_folders'),
        icon: 'fal fa-folders',
        path: '/quan-ly-thu-muc',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyPhanQuyen())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('model.employee_iglocal.permission'),
        icon: 'fal fa-user-lock',
        path: '/quan-ly-phan-quyen',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessThongKeLuong())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('salary_statistics'),
        icon: 'fal fa-chart-line',
        path: '/thong-ke-luong',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessTranslation())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('transition'),
        icon: 'fal fa-language',
        path: '/translation',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyKhachHangMoi())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('quan_ly_danh_sach_ngan_hang'),
        icon: 'fal fa-building-columns',
        path: '/quan-ly-ngan-hang',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyKhachHangMoi())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('manage_hospital'),
        icon: 'fal fa-building-columns',
        path: '/quan-ly-benh-vien',
      }}}):_vm._e(),_vm._v(" "),(_vm.roleManager.canAccessQuanLyThuVienCauHoi())?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('question_library'),
        icon: 'fal fa-books',
        path: '/quan-ly-thu-vien-cau-hoi',
      }}}):_vm._e(),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('settings'),
        icon: 'fal fa-gear',
        path: '/action-log',
      }}}),_vm._v(" "),_c('CheckConnection')]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }