<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="name"
    :rules="rulesData"
    :class="isFlex ? 'd-flex w-100' : ''"
    :tag="templateTag"
  >
    <div
      class="form-group"
      :class="isFlex ? 'd-flex flex-1 flex-column' : ''"
      :style="noMarginBottom ? 'margin-bottom: 0;' : ''"
    >
      <label
        v-if="label"
        class="form-control-label"
        :class="[isFlex ? 'd-flex flex-1 flex-column' : '', labelClasses]"
        ><strong v-if="required" class="text-danger">*</strong>
        {{ label }}</label
      >
      <div class="has-label">
        <el-select
          v-if="!group"
          v-model="valueData"
          class="v-select-single"
          :placeholder="placeholder"
          :disabled="disabled"
          :filterable="filterable"
          :allow-create="allowCreate"
          :clearable="clearable"
          :multiple="multiple"
          :loading="loading"
          @change="onChange"
          @blur="() => $emit('blur', valueData)"
          @visible-change	="() => $emit('visible-change', valueData)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="!optionRawValue ? item.value : item"
            :disabled="item.disabled"
          />
        </el-select>
        <el-select
          v-if="group"
          v-model="valueData"
          class="v-select-group"
          :placeholder="placeholder"
          :disabled="disabled"
          :filterable="filterable"
          :allow-create="allowCreate"
          :loading="loading"
          @change="onChange"
          @blur="() => $emit('blur', valueData)"
          @visible-change	="() => $emit('visible-change', valueData)"
        >
          <el-option-group
            v-for="group in options"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="!optionRawValue ? item.value : item"
              :disabled="item.disabled"
            />
          </el-option-group>
        </el-select>
      </div>
      <div v-if="errors[0]" class="mt-2">
        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VeeSelect',
  component: {},
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    rules: {
      type: String,
      default: () => '',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: () => false,
    },
    filterable: {
      type: Boolean,
      default: () => false,
    },
    allowCreate: {
      type: Boolean,
      default: () => false,
    },
    group: {
      type: Boolean,
      default: false,
    },
    isFlex: {
      type: Boolean,
      default: () => false,
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    optionRawValue: {
      type: Boolean,
      default: false,
    },
    labelClasses: {
      type: String,
      default: () => '',
    },
    templateTag: {
      type: String,
      default: () => 'div',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueData: this.value,
      rulesData: this.rules,
    }
  },
  watch: {
    value() {
      this.valueData = this.value
    },
    rules(v) {
      this.rulesData = v
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
  mounted() {
    if (!this.required) {
      this.rulesData = ''
    } else {
      this.rulesData = 'required'
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
}
</style>
