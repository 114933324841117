

export default (role, permissions) => {

  return {
        canBilling() {
            return true;
        },
        canAccessQuanLyKeToan() {
            return true;
        },
        canAccessKhachHang() {
            return true;
        },
        canAccessPheDuyet() {
            return role == "director" || permissions.includes('manage_clients') || permissions.includes('INTERNAL_UPDATE_CLIENT');
        },
        canAccessQuanLyKhachHangMoi() {
            return role == "director" || permissions.includes('manage_clients');
        },
        canAccessCongThucChung() {
            return role == "staff" || role == "leader" || role == "director";
        },
        canAccessYeuCauHoTro() {
            return role == "staff" || role == "leader" || role == "director";
        },
        canAccessFAQ() {
          return role == "staff" || role == "leader" || role == "director";
      },
        canAccessNgayNghiLe() {
            return role == "staff" || role == "leader" || role == "director";
        },
        canAccessQuanLyTemplate() {
            return role == "director" || permissions.includes('manage_export_template');
        },
        canAccessTranslation() {
            return role == "staff" || role == "leader" || role == "director";
        },
        canAccessThuVienCauHoi() {
            return role == "staff" || role == "leader" || role == "director";
        },
        canAccessQuanLyNhanVienIGLocal() {
            return role == "director" || permissions.includes('manage_iglocal_user');
        },
        // MH khach-hang/tong-quan.vue, khach-hang/import.vue
        canImport() {
            return role == "staff" || role == "leader";
        },
        canEditThietLapCoBan() {
            return role == "staff" || role == "leader";
        },
        canAccessThietLapYeuCau() {
            return role == "staff" || role == "leader" || role == "director";
        },
        canAccessQuanLyPhanQuyen() {
            return role == "director";
        },
        canAccessQuanLyCongThuc() {
            return role == "director";
        },
        canAccessQuanLyThuVienCauHoi() {
            return role == "director";
        },
        canAccessThongKeLuong() {
            return role == "director" || permissions.includes('manage_report_payroll');
        },
        canAccessDebitRequest() {
            return role == "accountant" || role == "director" || permissions.includes('manage_debit_request');
        },
        canAccessSalaryStatistics() {
          return role == "director";
        }
    }
}
